<template lang="pug">
v-card(v-if="listDevice.length").text-left
  v-card-title {{ $t('YourDevice') }}:
  v-card-text
    v-divider
    div(v-for="device in listDevice" :key="device.id").w-100.py-4.d-flex.justify-space-between.align-center
      div.d-flex
        div.mr-5
          v-img(src="@/assets/images/Authenticator_Icon.svg" width="36" alt="Authenticator_Icon.svg")
        div
          div
            b Authenticator
          div
            b {{ $t('nameDevice') }}: {{ device.name }}
      div
        ControlButtons(:controlButtons="controlButtons" :item="device")
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ListDevices',
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  data () {
    return {
      isLoading: false,
      routerName: ['security-your-device'],
      controlButtons: [
        {
          id: 1,
          name: 'mdi-trash-can-outline',
          action: item => this.deleteDevice(item),
          color: 'red',
          tooltip: this.$t('tooltip.delete'),
          buttonOptions: { xSmall: false, outlined: true, fab: false, icon: true, color: 'error' }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      listDevice: state => state.user.listDevice,
      user: state => state.main.user
    })
  },
  mounted () {
    this.getDevices()
  },
  methods: {
    ...mapActions(['getListDeviceWithTOTPForUser', 'removeTOTPForUser']),
    async getDevices () {
      this.isLoading = true
      await this.getListDeviceWithTOTPForUser({ user_pk: this.user.id })
    },
    async deleteDevice (item) {
      this.$swal({
        icon: 'warning',
        title: this.$t('notify.title.warning'),
        text: this.$t('Do you want delete TOTP'),
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async confirm => {
        if (confirm) {
          const response = await this.removeTOTPForUser({ id: item.id })
          if (response.code === 204) {
            this.getDevices()
            this.$notification.success('notify.success.deleteTOTP')
          }
        }
      })
    }
  }
}
</script>
